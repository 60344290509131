import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "../brand";
import {utagDataPost} from "@/functions/utagDataPost";

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: () => import("@/views/pages/LandingPage"),
    },
    {
        path: '/question-screen',
        name: 'QuestionScreen',
        component: () => import("@/views/pages/QuestionScreen"),
    },
    {
        path: '/leaderboard',
        name: 'LeaderboardScreen',
        component: () => import("@/views/pages/LeaderboardScreen"),
    },
    {
        path: '/game-complete',
        name: 'GameCompletedPage',
        component: () => import("@/views/pages/GameCompletedPage"),
    },
    {
        path: '/game-fail',
        name: 'GameFailPage',
        component: () => import("@/views/pages/GameFailPage"),
    },
    {
        path: '/manage-subscription',
        name: 'ManageSubscription',
        component: () => import("@/views/pages/ManageSubscription"),
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsConditions',
        component: () => import("@/views/pages/TermsConditions"),
    },
    {
        path: '/login',
        name: 'RequestOTP',
        component: () => import("@/views/pages/onboarding/RequestOTP"),
    },
    {
        path: '/submit-otp',
        name: 'SubmitOTP',
        component: () => import("@/views/pages/onboarding/SubmitOTP"),
    },
    {
        path: '/opt-in',
        name: 'DoiOne',
        component: () => import("@/views/pages/onboarding/DoiOne"),
    },
    {
        path: '/opt-in-confirmation',
        name: 'DoiTwo',
        component: () => import("@/views/pages/onboarding/DoiTwo"),
    },
    {
        path: '/content-block',
        name: 'ContentBlock',
        component: () => import("@/views/pages/onboarding/ContentBlock"),
    },
    {
        path: '/create-username',
        name: 'CreateUsername',
        component: () => import("@/views/pages/onboarding/CreateUsername"),
    },
    {
        path: '/welcome',
        name: 'WelcomePage',
        component: () => import("@/views/pages/onboarding/WelcomePage"),
    },
    {
        path: '/error',
        name: 'ErrorPage',
        component: () => import("@/views/pages/ErrorPage"),
    },
    {
        path: '/rewards',
        name: 'RewardsPage',
        component: () => import("@/views/pages/RewardsPage"),
    },
    {
        path: '/timeout-error',
        name: 'TimeoutError',
        component: () => import("@/views/pages/onboarding/TimeoutError"),
    },
    {
        path: '/insufficient-funds',
        name: 'InsufficientFunds',
        component: () => import("@/views/pages/onboarding/InsufficientFunds"),
    },
    {
        path: '/generic-error',
        name: 'GenericError',
        component: () => import("@/views/pages/onboarding/GenericError"),
    },
    {
        path: '/content-block-opt-in',
        name: 'ContentBlockOptIn',
        component: () => import("@/views/pages/onboarding/ContentBlockOptIn"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

function getBaseRoute(){
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
    return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

router.afterEach((to, from, next) => utagDataPost(to, from, next));

export default router
